
import * as sanityRuntime$PzO4D5HYtl from '/home/node/client/node_modules/@nuxt/image/dist/runtime/providers/sanity'
import * as bigcommerceRuntime$qQOUZ5Jrw1 from '/home/node/client/nuxt-img/bigcommerce/bigcommerce.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "bigcommerce",
  "domains": [
    "cdn11.bigcommerce.com"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['sanity']: { provider: sanityRuntime$PzO4D5HYtl, defaults: {"projectId":"syxmnik9","dataset":"production"} },
  ['bigcommerce']: { provider: bigcommerceRuntime$qQOUZ5Jrw1, defaults: {"baseURL":"https://cdn11.bigcommerce.com"} }
}
        